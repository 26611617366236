import React from 'react';
import { Box, Typography, Grid, Container, Button, FormControlLabel, Checkbox } from '@mui/material';
import { Security, AttachMoney, Warning, EventAvailable, Assessment, Build } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import lander from '../../assets/images/cyber_landing.webp'; // Adjust the path if needed

const benefits = [
  {
    title: 'Expert Guidance Tailored to Your Business',
    description:
      'With years of hands-on experience in cybersecurity, I offer expert advice that’s specifically tailored to the unique needs of your business, ensuring the right protection without unnecessary complexity.',
    icon: <Security />,
  },
  {
    title: 'Affordable, Customizable Security Solutions',
    description:
      'I provide cost-effective cybersecurity solutions that are customized to your business size and budget, ensuring you get the best protection without breaking the bank.',
    icon: <AttachMoney />,
  },
  {
    title: 'Proactive Threat Prevention',
    description:
      'Instead of just reacting to threats, I help your business stay one step ahead with proactive measures, continuously monitoring and adapting to new security risks.',
    icon: <Warning />,
  },
];

const steps = [
  {
    title: 'Step 1: Schedule a Meeting',
    description:
      'The first step is to schedule a meeting with me, where we can discuss your unique cybersecurity needs and identify potential vulnerabilities.',
    icon: <EventAvailable />,
  },
  {
    title: 'Step 2: Assess Your Current Security Landscape',
    description:
      'During the meeting, I’ll assess your existing security measures and identify gaps that could expose your business to threats.',
    icon: <Assessment />,
  },
  {
    title: 'Step 3: Develop a Custom Cybersecurity Strategy',
    description:
      'After the assessment, we’ll develop a tailored cybersecurity strategy that perfectly fits your business needs, ensuring long-term protection and peace of mind.',
    icon: <Build />,
  },
];

const features = [
    { title: 'Risk Assessment and Audits', description: 'Regular evaluations to identify vulnerabilities and risks in your current systems to ensure your defenses are up-to-date.' },
    { title: 'Data Encryption', description: 'Secures sensitive business data by converting it into unreadable code, protecting it from unauthorized access during transmission.' },
    { title: 'Firewall Protection', description: 'Implements robust firewall systems to monitor and control incoming and outgoing network traffic, preventing unauthorized access.' },
    { title: 'Employee Security Training', description: 'Educates your staff on best practices, phishing scams, and how to recognize threats, reducing human error vulnerabilities.' },
    { title: 'Multi-Factor Authentication', description: 'Adds an extra layer of security by requiring multiple forms of verification before granting access to sensitive systems and data.' },
    { title: '24/7 Monitoring', description: 'Continuous surveillance of your network and systems to detect and respond to any suspicious activities or threats in real-time.' },
    { title: 'Incident Response Plan', description: 'Prepares your business for swift action in case of a security breach, minimizing damage and downtime during a cyberattack.' },
    { title: 'Secure Cloud Storage Solutions', description: 'Safely stores your business data in the cloud with robust encryption and access control measures, ensuring data is always protected.' },
    { title: 'Regular Software Updates', description: 'Ensures that all software is kept up-to-date with the latest patches and security improvements to avoid vulnerabilities.' },
    { title: 'Secure Network Access', description: 'Implements Virtual Private Networks (VPNs) and other secure connection technologies to safeguard your network from external threats.' },
    { title: 'Backup and Disaster Recovery Plans', description: 'Ensures that critical data is regularly backed up and recoverable in the event of a breach or system failure, ensuring business continuity.' },
    { title: 'Customizable Security Solutions', description: 'Tailors security measures to fit your business needs, offering flexibility in managing different levels of access and protection across various systems.' },
  ];

function CyberSecurityLanding() {
  return (
    <Box sx={{ bgcolor: '#fff' }}>
      {/* Hero Section */}
      <Box
        sx={{
          textAlign: 'center',
          py: 8,
          px: 2,
          background: '#0d47a1',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
      {/* Section 1: Title Block with Background Color */}
        <Box sx={{ backgroundColor: '#1E47A1', color: 'white', padding: '0px', display: 'flex', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography variant="h2" align="left" gutterBottom>
                Defend Your Business with Budget-Friendly Security Solutions
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <img
            src={lander}
            alt="TRW Technology Group - Cyber Security"
            style={{ height: '250px', marginRight: '10px' }}
          />
            </Grid>
          </Grid>
        </Box>
        </Container>
        </Box>

        {/* Section 2: Why Cybersecurity Matters for Small Businesses */}
        <Box sx={{ backgroundColor: '#ffffff', padding: '40px 0' }}>
        <Container maxWidth="md">
        <Typography variant="body1" align="left" paragraph>
                In today’s digital age, cybersecurity is no longer a luxury—it’s a necessity. Cybercriminals increasingly target
                small businesses, assuming they lack the robust defenses of larger corporations. The consequences of a cyberattack
                can be devastating, from financial losses to reputational damage. But here’s the good news: you don’t need a massive
                budget to protect your business. With strategic planning and cost-effective solutions, you can build a formidable
                cybersecurity defense.
              </Typography>
              <Typography variant="body2" align="left" paragraph>
                In this post, we’ll explore budget-friendly security strategies to safeguard your small business while optimizing
                for Google searches to ensure this advice reaches those who need it most.
              </Typography>
              </Container>
              </Box>



        {/* Section 2: Why Cybersecurity Matters for Small Businesses */}
        <Box sx={{ backgroundColor: '#195293', color: 'white', padding: '40px 0' }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            Why Cybersecurity Matters for Small Businesses
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Cyberattacks are on the rise, with small businesses being disproportionately affected. According to a 2023 report by
            Cybersecurity Ventures, 60% of small businesses that suffer a cyberattack go out of business within six months. Hackers
            know small businesses often lack the resources for advanced cybersecurity, making them prime targets.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Investing in cybersecurity isn’t just about compliance or avoiding fines—it’s about protecting the trust of your
            customers, the integrity of your data, and the future of your business.
          </Typography>
          </Container>
        </Box>

{/* Section 3: 10 Budget-Friendly Security Solutions */}
<Box sx={{ backgroundColor: '#f4f6f8', padding: '40px 0' }}>
  <Container maxWidth="md">
    <Typography variant="h4" align="center" gutterBottom>
      10 Budget-Friendly Security Solutions for Your Business
    </Typography>
    <Grid container spacing={4} justifyContent="center">

{/* First Box */}
<Grid item xs={12}>
  <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
    {/* Number Box */}
    <Box sx={{
      backgroundColor: '#6a1b9a', // Change color as needed
      color: 'white',
      width: '200px', // Size of the number box
      height: '100px', // Height of the number box
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      marginRight: '15px' // Space between the number box and text
    }}>
      <Typography variant="h2">1</Typography>
    </Box>

    {/* Text Section */}
    <Box>
      <Typography variant="h6" gutterBottom>
        Educate Your Team on Cybersecurity Basics
      </Typography>
      <Typography variant="body2">
        Training employees is one of the most cost-effective ways to reduce cyber risk. Teach them to recognize phishing emails,
        create strong passwords, and avoid suspicious links. Free or low-cost online training programs can provide foundational
        knowledge.
      </Typography>
    </Box>
  </Box>
</Grid>

      {/* Second Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">2</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
            Enforce Strong Password Policies
          </Typography>
        <Typography variant="body2">
          Weak passwords are a leading cause of data breaches. Implement a policy requiring passwords to be at least 12 characters
          long and include a mix of letters, numbers, and symbols. Use a password manager like Bitwarden or LastPass, which offer
          free versions for small teams.
        </Typography>
        </Box>
      </Grid>

      {/* Third Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">3</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
          Enable Two-Factor Authentication (2FA)
          </Typography>
        <Typography variant="body2">
        Two-factor authentication adds an extra layer of security, requiring users to verify their identity using a second method, like a text message or app-based code. Platforms like Google Workspace and Microsoft 365 offer 2FA at no extra cost.
        </Typography>
        </Box>
      </Grid>

      {/* Fourth Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">4</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
          Regularly Update Software and Systems
          </Typography>
        <Typography variant="body2">
        Outdated software can have vulnerabilities that hackers exploit. Set systems to update automatically and ensure employees do the same on their devices. This simple step is both free and highly effective.
        </Typography>
        </Box>
      </Grid>

      {/* Fifth Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">5</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
            Leverage Free Cybersecurity Tools
          </Typography>
        <Typography variant="body2">
        Several organizations, including the Cybersecurity and Infrastructure Security Agency (CISA), offer free tools for small businesses. From vulnerability scans to network security assessments, these resources provide robust protection at no cost.
        </Typography>
        </Box>
      </Grid>

      {/* Sixth Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">6</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
            Use a Virtual Private Network (VPN)
          </Typography>
        <Typography variant="body2">
        For businesses with remote workers, a VPN encrypts internet connections, protecting sensitive data from prying eyes. Affordable VPN services like NordVPN or Surfshark provide business plans for less than $10 per month per user.
        </Typography>
        </Box>
      </Grid>

      {/* Seventh Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">7</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
          Perform Regular Data Backups
          </Typography>
        <Typography variant="body2">
        Data backups are essential for recovery in case of ransomware attacks or accidental data loss. Use cloud-based services like Google Drive or Dropbox for affordable, automated backups.
        </Typography>
        </Box>
      </Grid>

      {/* Eighth Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">8</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
          Invest in a Basic Endpoint Security Solution
          </Typography>
        <Typography variant="body2">
        Antivirus and endpoint security software are foundational defenses. Solutions like Malwarebytes or Avast offer free or low-cost business plans tailored to small enterprises.
        </Typography>
        </Box>
      </Grid>

      {/* Ninth Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">9</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
          Segment Your Network
          </Typography>
        <Typography variant="body2">
        Network segmentation limits the impact of a breach. Separate sensitive data and systems from less critical operations. For example, keep customer payment systems isolated from employee Wi-Fi.
        </Typography>
        </Box>
      </Grid>

      {/* Tenth Box */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
          <Box sx={{
            backgroundColor: '#6a1b9a',
            color: 'white',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginRight: '15px'
          }}>
            <Typography variant="h6">10</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
          Create an Incident Response Plan
          </Typography>
        <Typography variant="body2">
        An incident response plan outlines the steps to take during a cyberattack. While creating one doesn’t cost anything, it ensures your team knows how to act quickly to mitigate damage.
        </Typography>
        </Box>
    </Grid>
    </Grid>
  </Container>
</Box>

        {/* Section 5: The Cost of Doing Nothing */}
        <Box sx={{ backgroundColor: '#195293', color: 'white', padding: '40px 0' }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            The Cost of Doing Nothing
          </Typography>
          <Typography variant="body1" align="left" paragraph>
            Not investing in cybersecurity can cost far more in the long run than implementing basic safeguards. A single data breach
            can lead to:
          </Typography>
          <ul>
            <li>Lost revenue</li>
            <li>Legal penalties</li>
            <li>Damage to reputation</li>
            <li>Downtime impacting productivity</li>
          </ul>
          <Typography variant="body1" align="center" paragraph>
            Remember, cybersecurity isn’t an expense—it’s an investment in your business's future. With the strategies above, even the
            smallest businesses can achieve robust protection.
          </Typography>
          </Container>
        </Box>

        {/* Section 6: Meet Tom */}
        <Box sx={{ backgroundColor: '#ffffff', padding: '40px 0' }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            Meet Tom: Your Cybersecurity Advocate
          </Typography>
          <Typography variant="body1" align="left" paragraph>
            Tom Ward, the founder of TRW Technology Group, has dedicated his career to helping small businesses thrive in a digital world.
            With years of experience in cybersecurity and a passion for empowering businesses, Tom understands the unique challenges
            small enterprises face. His practical, results-driven approach ensures you receive tailored solutions without unnecessary costs.
          </Typography>
          <Typography variant="body1" align="left" paragraph>
            Tom believes every business deserves to feel secure, no matter its size or budget. When you work with him, you’re not just
            getting a cybersecurity expert—you’re gaining a trusted partner who truly cares about your success.
          </Typography>
          </Container>
        </Box>

        {/* Section 7: Call to Action */}
        <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
        <Container maxWidth="md">
          <Typography variant="h5" gutterBottom>
            Ready to Protect Your Business?
          </Typography>
          <Typography variant="body1" paragraph>
            Schedule a meeting with our experts to learn more about how we can help you implement the best cybersecurity practices.
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Book My Meeting
          </Button>
          </Container>
        </Box>

        {/* Section 2: Benefits of Hiring Us */}
        <Box sx={{ backgroundColor: '#ffffff', padding: '40px 0' }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            Why Choose Our Cybersecurity Solutions
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {benefits.map((benefit, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ textAlign: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
                  <Box sx={{ fontSize: '40px', color: '#00796b' }}>{benefit.icon}</Box>
                  <Typography variant="h6" gutterBottom>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body2">{benefit.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          </Container>
        </Box>

        {/* Section 3: Steps */}
        <Box sx={{ backgroundColor: '#e1bee7', padding: '40px 0' }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            How We Work with You
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ textAlign: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
                  <Box sx={{ fontSize: '40px', color: '#00796b' }}>{step.icon}</Box>
                  <Typography variant="h6" gutterBottom>
                    {step.title}
                  </Typography>
                  <Typography variant="body2">{step.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          </Container>
        </Box>

        {/* Section 4: Features */}
        <Box sx={{ backgroundColor: '#ffffff', padding: '40px 0' }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            Key Features of Our Cybersecurity Solutions
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ textAlign: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
                  <Typography variant="h6">{feature.title}</Typography>
                  <Typography variant="body2">{feature.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          </Container>
        </Box>

        {/* Section 5: Call to Action */}
        <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
        <Container maxWidth="md">
          <Typography variant="h5" gutterBottom>
            Ready to Protect Your Business?
          </Typography>
          <Typography variant="body1" paragraph>
            Schedule a meeting with our experts to learn more about how we can help you implement the best cybersecurity practices.
          </Typography>
          <Button variant="contained" color="primary" size="large" component={Link} to="/contact">
            Book My Meeting
          </Button>
          </Container>
        </Box>
    </Box>
  );
}

export default CyberSecurityLanding;
