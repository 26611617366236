import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import Modal from 'react-modal';
import './BookingCalendar.css';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const timeSlots = [
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
];

Modal.setAppElement('#root');

function BookingPage() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState({ name: '', email: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    if (selectedDate) {
      fetchCalendarEvents(selectedDate);
    }
  }, [selectedDate]);

  const fetchCalendarEvents = async (date) => {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      const response = await fetch(`/api/availability?date=${formattedDate}`);
      if (response.ok) {
        const data = await response.json();
        setCalendarEvents(
          data.map((event) => ({
            start: new Date(event.start),
            end: new Date(event.end),
            title: 'Booked',
          }))
        );
      } else {
        console.error('Failed to fetch calendar events.');
      }
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  const handleDateClick = async (slotInfo) => {
    const { start } = slotInfo;
    setSelectedDate(start);
    setIsModalOpen(true);

    try {
      const formattedDate = start.toISOString().split('T')[0];
      const response = await fetch(`/api/availability?date=${formattedDate}`);
      if (response.ok) {
        const data = await response.json();
        setBookedSlots(data);
      } else {
        console.error('Failed to fetch availability.');
      }
    } catch (error) {
      console.error('Error fetching availability:', error);
    }
  };

  const handleTimeSlotClick = (timeSlot) => setSelectedTimeSlot(timeSlot);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  const handleSubmit = async () => {
    if (!selectedDate || !selectedTimeSlot || !contactInfo.name || !contactInfo.email) {
      alert('Please complete all fields.');
      return;
    }

    const startDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTimeSlot.split(/[: ]/);
    startDateTime.setHours(
      selectedTimeSlot.includes('PM') ? parseInt(hours) + 12 : parseInt(hours),
      parseInt(minutes)
    );

    const endDateTime = new Date(startDateTime);
    endDateTime.setMinutes(startDateTime.getMinutes() + 30);

    setLoading(true);

    try {
      const response = await fetch('/api/add-event', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: contactInfo.name,
          email: contactInfo.email,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(`Booking confirmed! Meeting link: ${data.meetingLink}`);
        setIsModalOpen(false);
        setSelectedTimeSlot('');
        setCalendarEvents((prev) => [
          ...prev,
          { start: startDateTime, end: endDateTime, title: 'Booked' },
        ]);
      } else {
        alert('Failed to create booking. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isTimeSlotBooked = (timeSlot) => {
    const [hours, minutes] = timeSlot.split(/[: ]/);
    const time = new Date(selectedDate);
    time.setHours(
      timeSlot.includes('PM') ? parseInt(hours) + 12 : parseInt(hours),
      parseInt(minutes)
    );

    return bookedSlots.some(
      (slot) =>
        new Date(slot.start).getTime() <= time.getTime() &&
        new Date(slot.end).getTime() > time.getTime()
    );
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Schedule an Appointment
      </Typography>
      <Typography textAlign="center" sx={{ marginBottom: '20px' }}>
        Choose a time that works for you.
      </Typography>

      <Calendar
        localizer={localizer}
        selectable
        onSelectSlot={handleDateClick}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: '20px 0' }}
      />

      {successMessage && (
        <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="h6" color="green">
            {successMessage}
          </Typography>
        </Box>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Select Time Slot"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
          content: {
            maxWidth: '500px',
            margin: 'auto',
            padding: '20px',
          },
        }}
      >
        <Typography variant="h5" gutterBottom>
          Select a Time Slot for {selectedDate ? format(selectedDate, 'PP') : ''}
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mb: 3 }}>
          {timeSlots.map((time, index) => (
            <Button
              key={index}
              variant={time === selectedTimeSlot ? 'contained' : 'outlined'}
              disabled={isTimeSlotBooked(time) || (loading && time === selectedTimeSlot)}
              onClick={() => handleTimeSlotClick(time)}
            >
              {loading && time === selectedTimeSlot ? (
                <CircularProgress size={20} />
              ) : (
                time
              )}
            </Button>
          ))}
        </Box>

        <TextField
          label="Name"
          name="name"
          fullWidth
          value={contactInfo.name}
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          name="email"
          fullWidth
          value={contactInfo.email}
          onChange={handleInputChange}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Confirm Booking'}
        </Button>
      </Modal>
    </Box>
  );
}

export default BookingPage;
